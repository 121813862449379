import React from "react"

const LogoSprite = () => {

    return (
        <>
            <svg width="48" height="48" version="1.1" viewBox="0 0 132.29 132.29" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(-.084924 -.1032)">
            <g transform="translate(18.659)">
            <g transform="matrix(1.3353,0,0,1.3353,-24.005,-3.0408)">
                <g transform="translate(4.5305 -15.686)">
                <g transform="matrix(1.0974 0 0 1.0974 26.874 30.466)">
                <circle cx="20.228" cy="33.816" r="45.139" fill="#666" strokeWidth="3.0096"/>
                </g>
                <g transform="matrix(.6963 0 0 .6963 16.091 32.481)" fill="#fff">
                <path d="m87.218 49.162 1.2444 15.22h-1.2444c-1.2576-3.7936-2.8806-6.65-4.8722-8.5703-2.8685-2.7741-6.5556-4.1612-11.059-4.1612-6.1408 0-10.812 2.4306-14.012 7.2908-2.6797 4.1019-4.0185 8.9851-4.0185 14.652 0 4.6001 0.88885 8.7964 2.6677 12.589 1.7777 3.7936 4.1074 6.5731 6.9879 8.3399 2.8806 1.7667 5.8368 2.649 8.8721 2.649 1.7788 0 3.4973-0.22496 5.1565-0.67597 1.6592-0.44992 3.2602-1.1138 4.8009-1.9906v-13.941c0-2.4186-0.18436-4.0009-0.55087-4.7482-0.36871-0.7473-0.93714-1.3157-1.7075-1.7075-0.77034-0.39065-2.1278-0.58708-4.0723-0.58708v-1.3508h18.67v1.3508h-0.88886c-1.8501 0-3.1187 0.61671-3.8056 1.8501-0.47515 0.87679-0.71108 2.6073-0.71108 5.1916v14.758c-2.7258 1.4705-5.4176 2.5492-8.0721 3.2361-2.6545 0.68694-5.6064 1.0304-8.8545 1.0304-9.3165 0-16.393-2.987-21.23-8.9621-3.6278-4.4805-5.4418-9.649-5.4418-15.504 0-4.2435 1.0194-8.3092 3.0583-12.198 2.4186-4.6231 5.7381-8.1797 9.9574-10.668 3.5324-2.0619 7.7045-3.0934 12.518-3.0934 1.7547 0 3.3491 0.14156 4.7834 0.42687 1.4342 0.28423 3.4665 0.913 6.0991 1.8853 1.3267 0.4982 2.2166 0.7473 2.6677 0.7473 0.44992 0 0.83509-0.20742 1.1555-0.6222 0.32043-0.41371 0.51576-1.2268 0.58708-2.4361z"/>
                <path d="m44.001 2.3546 0.53331 11.308h-1.3508c-0.26007-1.9917-0.61671-3.4139-1.0666-4.2676-0.73523-1.3739-1.7141-2.3878-2.9343-3.0397-1.2203-0.65183-2.8268-0.97774-4.8185-0.97774h-6.7915v36.839c0 2.964 0.32044 4.813 0.96018 5.5471 0.89983 0.9953 2.288 1.4935 4.1601 1.4935h1.6713v1.3157h-20.446v-1.3157h1.7075c2.0378 0 3.4852-0.61671 4.3378-1.849 0.52124-0.75827 0.78241-2.4888 0.78241-5.1916v-36.84h-5.7973c-2.2529 0-3.8528 0.16569-4.7998 0.4982-1.2334 0.45101-2.2891 1.3157-3.1659 2.5963-0.87678 1.2795-1.398 3.01-1.5648 5.1916h-1.3508l0.56952-11.308z"/>
                <path d="m14.538 91.142 59.755-80.511"/>
                <g transform="matrix(1.0974,0,0,1.0974,4.0677,2.3546)" shapeRendering="auto">
                <path d="m9.54 81.411c-0.104 0-0.208-0.032-0.297-0.099-0.222-0.165-0.268-0.478-0.104-0.699l54.455-73.367c0.165-0.221 0.479-0.267 0.699-0.104 0.222 0.165 0.268 0.478 0.104 0.699l-54.455 73.368c-0.098 0.132-0.249 0.202-0.402 0.202z"/>
                <path d="m64.303 7.1289c-0.22701-0.16819-0.55052-0.12052-0.7207 0.10742l-54.455 73.367c-0.16916 0.22796-0.1215 0.55056 0.10742 0.7207 0.091482 0.06833 0.19802 0.10352 0.30469 0.10352 0.15788 0 0.31494-0.07284 0.41602-0.20898l54.455-73.369c0.16916-0.22796 0.1215-0.55056-0.10742-0.7207zm-0.01953 0.025391c0.21507 0.15985 0.2604 0.46369 0.10156 0.67773l-54.455 73.367c-0.094925 0.12786-0.24251 0.19531-0.39062 0.19531-0.10072 0-0.20094-0.03083-0.28711-0.0957-0.21507-0.15986-0.25845-0.46174-0.099609-0.67578l54.455-73.367c0.15982-0.21406 0.46279-0.25937 0.67578-0.10156z"/>
                </g>
                </g>
                </g>
            </g>
            </g>
            </g>
            </svg>
        </>
    )
}

export default LogoSprite